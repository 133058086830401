import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import Closecircle from "../../assets/img/Closecircle.svg";
import Spotify from "../../assets/img/Spotify.svg";
import Applemusic from "../../assets/img/Applemusic.svg";
import Venmo from "../../assets/img/Venmo.svg";
import Paypal from "../../assets/img/Paypal.svg";
import {
  closeIntegrationModal,
  openCaptchaModal,
} from "../../redux/Reducers/modalReducer";
import { useDispatch } from "react-redux";
import { addUserDetails } from "../../api/api";
import { toast } from "react-toastify";
import { setUser, setUserLogin } from "../../redux/Reducers/userReducer";
import Backicon from "../../assets/img/Backicon.svg";

const Integrations = (props) => {
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const response = await addUserDetails({
      is_music_payment_account_added: true,
    });
    if (response.data.is_captacha_verified === true) {
      toast.success(response.message);
      dispatch(setUser(response.data.user));
      dispatch(closeIntegrationModal());
      dispatch(setUserLogin());
    } else {
      dispatch(closeIntegrationModal());
      dispatch(openCaptchaModal());
    }
  };
  const back = () => {
    dispatch(closeIntegrationModal());
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <div className="flex_login_container">
          <div className="login_video">
            <video
              autoPlay
              loop
              muted
              playsinline = "true"
              className="w-full h-full object-cover intergration_videos"
            >
              <source src="https://tribe-in.com/video/IntegrationsVideo.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="welcome_logo">
            <div className="completeyour_profile">
              <h2 onClick={back} className="back_arrow_signin">
                <div className="back_profile_icon d-none">
                  <img src={Backicon} alt="logo-icon" className="Backicon" />
                </div>
                Complete your profile
              </h2>
            </div>
            <div className="personal_info">
              <h2>
                <span>5</span> Integrations{" "}
              </h2>
              <ProgressBar now={100} />;
            </div>
            <Form>
              <div className="social_media_group integration_social_media_icon">
                <ul className="social_media_list mb-4">
                  <h3 className="profile_label mb-0">Music</h3>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx ">
                        <div className="social_media_icon">
                          <img src={Spotify} alt="Spotify" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Spotify account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>Connect</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Applemusic} alt="Applemusic" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Apple Music account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>Connect</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul className="social_media_list">
                  <h3 className="profile_label mb-0"> Payment</h3>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Venmo} alt="Spotify" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your Venmo account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <span>Connect</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="social_media_crd">
                      <div className="social_media_icon_flx">
                        <div className="social_media_icon">
                          <img src={Paypal} alt="Applemusic" />
                        </div>
                        <div className="social_media_txt">
                          <p>Connect your PayPal account</p>
                        </div>
                      </div>
                      <div className="connect_txt">
                        <button className="close_btn">
                          <img src={Closecircle} alt="Closecircle" />
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="completeyour_profile_btn">
                <Button variant="secondary" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Integrations;
