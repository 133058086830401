import React from "react";
import {
  FacebookLoginButton,
  InstagramLoginButton,
} from "react-social-login-buttons";
import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
const FacebookLogin = () => {
  return (
    <div>
      <LoginSocialInstagram
        appId="744452924363990"
        onResolve={(response) => {
          console.log(response);
        }}
        onReject={(error) => {
          console.log(error);
        }}
      >
        <InstagramLoginButton />
      </LoginSocialInstagram>
    </div>
  );
};

export default FacebookLogin;
