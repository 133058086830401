import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EventVideo from "../../assets/img/EventVideo.mp4";
import EditProfileImg from "../../assets/img/EditProfileImg.jpg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import EmptyImg from "../../assets/img/EmptyImg.svg";
import FlashCam from "../../assets/img/FlashCam.svg";
import Closecircle from "../../assets/img/Closecircle.svg";
import { addUserDetails, getUserByToken, uploadFile } from "../../api/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { closeUpdateProfilePic } from "../../redux/Reducers/modalReducer";
import Backicon from "../../assets/img/Backicon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/Reducers/userReducer";
import { removePrefix } from "../../helpers/utils";

const UpdateProfilePic = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null);
  const [picUrl, setPicUrl] = useState(null);
  const [oldPic, setOldPic] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const back = () => {
    dispatch(closeUpdateProfilePic());
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // image size restriction
    if (file && file.size > 15 * 1024 * 1024) {
      toast.error("File size should be less than 15 MB.");
      return;
    }
    setPicUrl(removePrefix(file.name));
    formik.setFieldValue("profilePicture", file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleClearSelection = () => {
    formik.setValues({
      profilePicture: null,
    });
    setPreviewImage(null);
    setPicUrl(null);
    setOldPic(null);
  };

  // functions for drag and drop functionality
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const uploadPicToServer = (formData) => {
    return new Promise(async (resolve, reject) => {
      const response = await uploadFile(formData);
      if (response.statusCode === 201) {
        resolve(response.data);
      } else {
        reject(response.error);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      profilePicture: null,
    },
    onSubmit: async (values) => {
      if (oldPic) {
        try {
          const response = await addUserDetails({ profile_pic: oldPic });
          if (response.statusCode === 200) {
            dispatch(setUser(response?.data));
            dispatch(closeUpdateProfilePic());
          } else {
            toast.error(response.error);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        const formData = new FormData();
        formData.append("file", values.profilePicture);
        uploadPicToServer(formData)
          .then(async (result) => {
            let values = {
              profile_pic: result,
            };
            const response = await addUserDetails(values);

            if (response.statusCode === 200) {
              dispatch(setUser(response.data));
              dispatch(closeUpdateProfilePic());
            } else {
              toast.error(response.error);
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    },

    validationSchema: Yup.object({
      profilePicture: Yup.mixed().required("Please select a profile picture"),
    }),
  });

  useEffect(() => {
    const fetchData = async () => {
      let response = await getUserByToken();
      if (response?.data?.profile_pic !== null) {
        setPreviewImage(response?.data?.profile_pic);
        setPicUrl(removePrefix(response?.data?.profile_pic));
        setOldPic(response?.data?.profile_pic);
      }
      formik.setValues({
        profilePicture: response?.data?.profile_pic,
      });
    };
    fetchData();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile completeyour_profile_photo"
        centered
      >
        <div
          className={`flex_login_container ${isDragging ? "dragging" : ""}`}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="login_video">
            <div className="login_video_img">
              <img src={EditProfileImg} alt="EditProfileImg" />
            </div>
            {/* <video autoPlay loop muted className="w-full h-full object-cover">
              <source src={EventVideo} type="video/mp4" />
            </video> */}
          </div>
          <div className="welcome_logo">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="completeyour_profile">
                  <h2 onClick={back} className="back_arrow_signin">
                    <div className="back_profile_icon d-none">
                      <img
                        src={Backicon}
                        alt="logo-icon"
                        className="Backicon"
                      />
                    </div>
                    Update your profile
                  </h2>
                </div>
              </Modal.Title>
            </Modal.Header>
            <div className="personal_info">
              <h2>
                <img className="cam_flash" src={FlashCam} alt="FlashCam" />{" "}
                Profile photo
              </h2>
            </div>
            {/* <h3 className="profile_label"> Profile photo</h3> */}
            <Form>
              {previewImage ? (
                <div className="show_profile_pic">
                  <div className="show_profile_pic_flx">
                    <div className="show_profile_img">
                      <img src={previewImage} alt="Emilyprofile" />
                    </div>
                    <div className="show_profile_txt">
                      <p>{`${picUrl}`}</p>
                    </div>
                  </div>
                  <div className="close_btn">
                    <button>
                      <img
                        src={Closecircle}
                        alt="Closecircle"
                        onClick={handleClearSelection}
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className={`form_row ${isDragging ? "dragging-over" : ""}`}
                >
                  <div className="profile_photo_upload">
                    <label for="profile_photo">
                      <div className="empty_img">
                        <img src={EmptyImg} alt="EmptyImg" />
                      </div>

                      <div className="profile_photo_txt">
                        <h4>
                          <span>Click to upload </span>or drag and drop
                        </h4>
                        <p>Max. File size: 15MB</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="profile_photo"
                      name="profilePicture"
                      accept="image/jpeg, image/png, image/gif, image/bmp"
                      onChange={handleFileChange}
                      className="d-none"
                    />
                    {formik.touched.profilePicture &&
                      formik.errors.profilePicture && (
                        <small style={{ color: "red" }}>
                          {formik.errors.profilePicture}
                        </small>
                      )}
                  </div>
                </div>
              )}
              <div className="completeyour_profile_btn profile_photo_btn">
                <Button
                  variant="secondary"
                  className={
                    formik.values.profilePicture ? "is_btn_active" : "secondary"
                  }
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateProfilePic;
