import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeHideMyLocationFromUser } from "../../redux/Reducers/modalReducer";
import { useNavigate } from "react-router-dom";
import { hideLocation } from "../../api/api";
import { toast } from "react-toastify";

const HideMyLocationFromUserModal = (props) => {
  const dispatch = useDispatch();
  const { hideLocationFrom } = useSelector((state) => state?.model);
  const handleYes = async () => {
    try {
      const response = await hideLocation({
        from_user_id: hideLocationFrom.user_id,
      });
      if (response?.statusCode === 200) {
        toast.success(response?.message);
      }
      dispatch(closeHideMyLocationFromUser());
    } catch (error) {
      console.log(error);
    }
  };

  const handleNo = () => {
    dispatch(closeHideMyLocationFromUser());
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_icon">
              {/* <img src={Succes} alt="Succes" /> */}
            </div>
            <div className="reportsuccessfully_txt">
              <h2>Are you sure you want to hide your location from this user?</h2>
              <p>
              By hiding your location, you will also no longer see their location. Do you want to proceed?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0 d-flex">
            <Button
              variant="danger"
              className="btn_blue_large"
              onClick={handleYes}
            >
              Yes, Hide Location
            </Button>
            <Button
              variant="primary"
              className="btn_blue_large is_btn_active"
              onClick={handleNo}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HideMyLocationFromUserModal;
