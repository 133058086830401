import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  openTribe,
  openLogin,
  openSignup,
} from "../../redux/Reducers/modalReducer";
import { verifyToken } from "../../api/api";

const Footer = ({ identifier, name }) => {
  const dispatch = useDispatch();
  const { isUserLogedIn } = useSelector((state) => state.user);
  const [currentButton, setCurrentButton] = useState(0);
  const buttons = [
    {
      text: "JOIN IN",
      bgColor: "#FFF614",
      textColor: "#292833",
      borderColor: "#FFF614",
      width: "100%",
    },
    {
      text: "TRIBE IN",
      bgColor: "#FF53DE",
      borderColor: "#FF53DE",
      width: "100%",
    },
    {
      text: "TAP IN",
      bgColor: "#2957FF",
      borderColor: "#2957FF",
      width: "100%",
    },
    {
      text: "DIVE IN",
      bgColor: "#FF53DE",
      borderColor: "#FF53DE",
      width: "100%",
    },
  ];

  const data = {
    eventId: identifier,
    eventName: name,
  };

  const handleButtonClick = async () => {
    dispatch(openTribe(data));
  };

  const openSignUpScreen = () => {
    dispatch(openLogin());
  };

  const intervalTime = 2000; // Interval in milliseconds (e.g., 2000ms = 2 seconds)
  const nextButton = () => {
    setCurrentButton((currentButton + 1) % buttons.length);
  };

  useEffect(() => {
    const interval = setInterval(nextButton, intervalTime);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentButton]);

  return (
    <footer className="main_footer">
      <div className="footer_txt">
        <p>Connect with everyone attending {name}!</p>
      </div>
      <div className="footer_btn">
        {isUserLogedIn ? (
          <Button
            onClick={handleButtonClick}
            variant="primary"
            className="btn_blue"
          >
            COME IN
          </Button>
        ) : (
          buttons.map((button, index) => (
            <Button
              key={index}
              style={{
                display: index === currentButton ? "block" : "none",
                backgroundColor: button.bgColor,
                color: button.textColor,
                border: button.borderColor,
                width: button.width,
              }}
              onClick={openSignUpScreen}
            >
              {button.text}
            </Button>
          ))
        )}
      </div>
    </footer>
  );
};

export default Footer;
