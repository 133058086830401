import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp } from "../../api/api";
import { toast } from "react-toastify";
import { closeLogin, openOtpModal } from "../../redux/Reducers/modalReducer";
import { Link } from "react-router-dom";
import { setOtpCredToResend } from "../../redux/Reducers/userReducer";
import { useFormik } from "formik";
import { phoneNumberValidationSchema } from "../../helpers/validation";
import { removeSubstringFromBeginning } from "../../helpers/utils";
import Backicon from "../../assets/img/Backicon.svg";
import { phone } from "phone";
import WelcomeLogo from "../../assets/img/WelcomeLogo.svg";

const Signin = (props) => {
  const { data } = useSelector((state) => state?.model);
  const [isValid, setIsValid] = useState(false);

  const formik = useFormik({
    initialValues: {
      country_code: "",
      phone_number: "",
    },

    validationSchema: phoneNumberValidationSchema,

    onSubmit: async (values, { setSubmitting }) => {
      let dataToSend;
      if (data?.user_id) {
        dataToSend = {
          ...values,
          phone_number: removeSubstringFromBeginning(
            values.phone_number,
            values.country_code
          ),
          device_type: "phone",
          user_id: data?.user_id,
        };
      } else {
        dataToSend = {
          ...values,
          phone_number: removeSubstringFromBeginning(
            values.phone_number,
            values.country_code
          ),
          device_type: "phone",
        };
      }

      const response = await sendOtp(dataToSend);
      if (response?.statusCode === 200) {
        localStorage.setItem("token", response.data.token);
        toast.success(response.message);
        dispatch(
          setOtpCredToResend({
            phone_number: removeSubstringFromBeginning(
              values.phone_number,
              values.country_code
            ),
            country_code: values.country_code,
          })
        );
        dispatch(
          openOtpModal({ 
            user_id: data?.user_id, 
            // otp : response?.data?.otp 
          })
        );
        dispatch(closeLogin());
      } else {
        toast.error(response.error);
      }
      setSubmitting(false);
    },
  });

  const handleChange = (value, country) => {
    formik.setValues({ country_code: country?.dialCode, phone_number: value });
    const valid = phone(`+${value}`, country.countryCode);
    setIsValid(valid.isValid);
  };

  const dispatch = useDispatch();

  const back = () => {
    dispatch(closeLogin());
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal signin_inner_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="welcome_logo pb-5 text-center">
              <div className="welcome_logo_img mb-0">
                {/* <img src={WelcomeLogo} alt="WelcomeLogo" /> */}
              </div>
            </div>
            <h2 onClick={back} className="back_arrow_signin text-center">
              <div className="back_profile_icon d-none">
                <img src={Backicon} alt="logo-icon" className="Backicon" />
              </div>{" "}
              Sign in or Sign up
            </h2>
          </Modal.Title>
        </Modal.Header>

        
        <Modal.Body>
          <h4>Please enter your Phone Number</h4>
          <p>
            By signing up for TribeIn, you agree to our <br />
            {/* <Link to="/">What happens if your number changes?</Link> */}
            <Link to="/termsconditions" onClick={() => dispatch(closeLogin())}>
              Terms & conditions
            </Link>
          </p>
          <Form className="phone_number_form">
            <div className="phone_label">
              <label>Country code</label>
              <PhoneInput
                className="enter_phone_no"
                name="phone_number"
                onBlur={formik.handleBlur}
                value={formik?.values?.phone_number}
                onChange={(value, country) => handleChange(value, country)}
                numInputs={6}
                country={"us"}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                }}
              />
              {formik.touched.phone_number && formik.errors.phone_number && (
                <small className="text-danger">
                  {formik.errors.phone_number}
                </small>
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={formik.handleSubmit}
            className={isValid && "is_btn_active"}
            disabled={!isValid}
          >
            JOIN TRIBE AND START CONNECTING
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Signin;
