import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LogoIcon from "../../assets/img/LogoIcon.svg";
import LogoTxt from "../../assets/img/LogoTxt.svg";
import {
  CloseSidebarIcon,
  GreyMYEvent,
  GreyPayment,
  GreyProfile,
  GreySetting,
  Greysuggestion,
  GreyTribes,
  HomeIconFill,
  Logout,
  OpenSidebarIcon,
  PaymentIconFilled,
  ProfileIconFilled,
  RightBackArrow,
  SettingsIconFilled,
  SuggestIconFilled,
  TermsIconFill,
} from "../utils/icon";
import { HomeIconOutline } from "../utils/icon";
import { EventIconFill } from "../utils/icon";
import { EventIconOutline } from "../utils/icon";
import { PaymentIconOutline } from "../utils/icon";
import { ProfileIconOutline } from "../utils/icon";
import { SettingsIconOutline } from "../utils/icon";
import { SuggestIconOutline } from "../utils/icon";
import { TermsIconOutline } from "../utils/icon";
import TribesIconFill from "../../assets/img/TribesIconFill.svg";
import TribesIconOutline from "../../assets/img/TribesIconOutline.svg";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmationModal,
  openLogin,
  openSignup,
} from "../../redux/Reducers/modalReducer";
import { CONFIRMATION_TYPES } from "../../helpers/constant";
import userprofileimage from "../../assets/img/profile-pic.svg";
import { ToggleIcon } from "../utils/icon";

function Sidebar({ sidebarVisible, setSidebarVisible, handleSidebar }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState();
  const { user, isUserLogedIn } = useSelector((state) => state?.user);

  const handleCloseSidebar = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    if (user && user.profile_pic === null) {
      setProfilePic(userprofileimage);
    } else {
      setProfilePic(`${user?.profile_pic}`);
    }
  }, [user]);

  const handleLogout = () => {
    const data = {
      mode: CONFIRMATION_TYPES.LOGOUT,
      message: "Are you sure you want to logout?",
    };
    dispatch(openConfirmationModal(data));
  };

  return (
    <>
      <div
        className={`main_sidebar text-center ${sidebarVisible ? "show" : ""}`}
      >
        {/* <div className='main_sidebar text-center'> */}
        <div className="sidebar_logo">
          <div className="toggle_btn cursor-pointer" onClick={handleSidebar}>
            {/* <ToggleIcon /> */}
            {console.log(sidebarVisible, "value")}
            {sidebarVisible ? <OpenSidebarIcon /> : <CloseSidebarIcon />}
          </div>
          <div
            className="sidebar_logo_small cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={LogoIcon} alt="logo-icon" className="logo_icon" />
            <img src={LogoTxt} alt="logo-icon" className="logo_txt" />
          </div>
        </div>
        <div className="after_login">
          {isUserLogedIn && (
            <div className="after_cntnt">
              <div className="avtar_img">
                <img
                  src={
                    !profilePic || profilePic.startsWith("public")
                      ? userprofileimage
                      : profilePic
                  }
                  alt="Avtar"
                />
              </div>
              <div className="avtar_txt">
                <p>{user && user?.first_name}</p>
              </div>
            </div>
          )}
          {isUserLogedIn && (
            <div
              className="right_back_arroe"
              onClick={() => handleCloseSidebar()}
            >
              <RightBackArrow />
            </div>
          )}
        </div>
        <div className="menu_list">
          <ul>
            <li>
              {!isUserLogedIn && (
                <Button
                  variant="primary"
                  className="btn_blue"
                  onClick={() => dispatch(openLogin())}
                >
                  Log In
                </Button>
              )}
            </li>
            <li>
              <Link
                to="/"
                className={location.pathname === "/" && "active_link"}
              >
                <span className="fill_icon">
                  <HomeIconOutline />
                </span>
                <span className="outline_icon">
                  <HomeIconFill />
                </span>
                <span className="text_name">Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/myTribes"
                className={
                  isUserLogedIn
                    ? location.pathname === "/myTribes" && "active_link"
                    : "disable_grey"

                }
                onClick={!isUserLogedIn ? (e) => e.preventDefault() : null}
              >
                {isUserLogedIn ? (
                  <>
                    <span className="fill_icon">
                      <img src={TribesIconOutline} alt="TribesIconOutline" />
                    </span>
                    <span className="outline_icon">
                      <img src={TribesIconFill} alt="TribesIconFill" />
                    </span>
                  </>
                ) : (
                  <span className="disable_icons">
                    <GreyTribes />
                  </span>
                )}

                <span className="text_name">Tribes</span>
              </Link>
            </li>
            <li>
              <Link
                to="/Myevents"
                className={
                  isUserLogedIn
                    ? location.pathname === "/Myevents" && "active_link"
                    : "disable_grey"
                }
                onClick={!isUserLogedIn ? (e) => e.preventDefault() : null}
              >
                {isUserLogedIn ? (
                  <>
                    {" "}
                    <span className="fill_icon">
                      <EventIconOutline />
                    </span>
                    <span className="outline_icon">
                      <EventIconFill />
                    </span>
                  </>
                ) : (
                  <span className="disable_icons">
                    <GreyMYEvent />
                  </span>
                )}

                <span className="text_name">My events</span>
              </Link>
            </li>
            <li>
              <Link
                to="/payment"
                className={
                  isUserLogedIn
                    ? location.pathname === "/payment" && "active_link"
                    : "disable_grey"
                }
                onClick={!isUserLogedIn ? (e) => e.preventDefault() : null}
              >
                {isUserLogedIn ? (
                  <>
                    <span className="fill_icon">
                      <PaymentIconOutline />
                    </span>
                    <span className="outline_icon">
                      <PaymentIconFilled />
                    </span>
                  </>
                ) : (
                  <span className="disable_icons">
                    <GreyPayment />
                  </span>
                )}
                <span className="text_name">Payment</span>
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className={
                  isUserLogedIn
                    ? location.pathname === "/profile" && "active_link"
                    : "disable_grey"
                }
                onClick={!isUserLogedIn ? (e) => e.preventDefault() : null}
              >
                {isUserLogedIn ? (
                  <>
                    {" "}
                    <span className="fill_icon">
                      <ProfileIconOutline />
                    </span>
                    <span className="outline_icon">
                      <ProfileIconFilled />
                    </span>
                  </>
                ) : (
                  <span className="disable_icons">
                    <GreyProfile />
                  </span>
                )}
                <span className="text_name">
                  Profile{" "}
                  {user && isUserLogedIn && !user?.is_preference_added && (
                    <small
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "15px",
                      }}
                    >
                      !
                    </small>
                  )}
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/settings"
                className={
                  isUserLogedIn
                    ? location.pathname === "/settings" && "active_link"
                    : "disable_grey"
                }
                onClick={!isUserLogedIn ? (e) => e.preventDefault() : null}
              >
                {isUserLogedIn ? (
                  <>
                    <span className="fill_icon">
                      <SettingsIconOutline />
                    </span>
                    <span className="outline_icon">
                      <SettingsIconFilled />
                    </span>
                  </>
                ) : (
                  <span className="disable_icons">
                    <GreySetting />
                  </span>
                )}
                <span className="text_name">Settings</span>
              </Link>
            </li>
            <li>
              <Link
                to="/suggestfeature"
                className={
                  isUserLogedIn
                    ? location.pathname === "/suggestfeature" && "active_link"
                    : "disable_grey"
                }
                onClick={!isUserLogedIn ? (e) => e.preventDefault() : null}
              >
                {isUserLogedIn ? (
                  <>
                    <span className="fill_icon">
                      <SuggestIconOutline />
                    </span>
                    <span className="outline_icon">
                      <SuggestIconFilled />
                    </span>
                  </>
                ) : (
                  <span className="disable_icons">
                    <Greysuggestion />
                  </span>
                )}

                <span className="text_name">Suggest a feature</span>
              </Link>
            </li>
            <li>
              <Link
                to="/termsconditions"
                className={
                  location.pathname === "/termsconditions" && "active_link"
                }
              >
                <span className="fill_icon">
                  <TermsIconOutline />
                </span>
                <span className="outline_icon">
                  <TermsIconFill />
                </span>
                <span className="text_name">Terms and conditions</span>
              </Link>
            </li>
          </ul>
          {isUserLogedIn && (
            <div className="logout_btn logout_btn_sidebar">
              <Link onClick={handleLogout} className="d-flex">
                <span className="logout_icon">
                  <Logout />
                </span>
                <span className="text_name">Logout</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className="main_sidebar_show"
        onClick={() => handleCloseSidebar()}
      ></div>
    </>
  );
}

export default Sidebar;

{
  /* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z" stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> */
}
