import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EditProfileImg from "../../assets/img/EditProfileImg.jpg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import EmptyImg from "../../assets/img/EmptyImg.svg";
import Closecircle from "../../assets/img/Closecircle.svg";
import { addUserDetails, getUserByToken, uploadFile } from "../../api/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  closeUploadPic,
  openCaptchaModal,
  openIntegrationModal,
  openPreferencesModal,
  openSocialConnectionModal,
} from "../../redux/Reducers/modalReducer";
import Backicon from "../../assets/img/Backicon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { setUser, setUserLogin } from "../../redux/Reducers/userReducer";

const Profilephoto = (props) => {
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null);
  const [picUrl, setPicUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let response = await getUserByToken();
      if (response?.data?.profile_pic !== null) {
        setPreviewImage(response?.data?.profile_pic);
        setPicUrl(response?.data?.profile_pic);
      }
    };
    fetchData();
  }, []);

  const back = () => {
    dispatch(closeUploadPic());
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 15 * 1024 * 1024) {
      toast.error("File size should be less than 15 MB.");
      return;
    }
    setPicUrl(file.name);
    formik.setFieldValue("profilePicture", file);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleClearSelection = () => {
    setPreviewImage(null);
    setPicUrl(null);
  };

  const handleSkip = async () => {
    try {
      const response = await addUserDetails({
        is_profile_pic_skipped: true,
      });
      if (response.statusCode === 200) {
        dispatch(closeUploadPic());
        dispatch(openPreferencesModal());
      }
    } catch (error) {
      console.log(error);
    }
  };

  // functions for drag and drop functionality
  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    handleFileChange({ target: { files: [file] } });
  };

  const uploadPicToServer = (formData) => {
    return new Promise(async (resolve, reject) => {
      const response = await uploadFile(formData);
      if (response.statusCode === 201) {
        resolve(response.data);
      } else {
        reject(response.error);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      profilePicture: null,
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("file", values.profilePicture);
      formData.append("file_type", "profilePic")

      uploadPicToServer(formData)
        .then(async (result) => {
          let values = {
            profile_pic: result,
          };

          const response = await addUserDetails({
            ...values,
            is_profile_pic_added: true,
          });

          if (response.statusCode === 200) {
            dispatch(setUser(response.data));
            if (response.data.is_preference_added === true) {
              if (
                response.data.is_social_accounts_added === true ||
                response.data.is_social_accounts_skipped === true
              ) {
                if (response.data.is_music_payment_account_added === true) {
                  if (response.data.is_captacha_verified === true) {
                    toast.success(response?.message);
                    dispatch(setUser(response?.data));
                    dispatch(closeUploadPic());
                    dispatch(setUserLogin());
                  } else {
                    dispatch(setUserLogin(true));
                    dispatch(setUser(response?.data));
                    dispatch(closeUploadPic());
                    dispatch(openCaptchaModal());
                  }
                } else {
                  dispatch(setUserLogin(true));
                  dispatch(setUser(response?.data));
                  dispatch(closeUploadPic());
                  dispatch(openIntegrationModal());
                }
              } else {
                dispatch(setUserLogin(true));
                dispatch(setUser(response?.data));
                dispatch(closeUploadPic());
                dispatch(openSocialConnectionModal());
              }
            } else {
              toast.success(response?.message);
              dispatch(setUser(response?.data));
              dispatch(setUserLogin(true));
              dispatch(closeUploadPic());
              dispatch(openPreferencesModal());
            }
          } else {
            toast.error(response.error);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    },
    validationSchema: Yup.object({
      profilePicture: Yup.mixed().required("Please select a profile picture"),
    }),
  });

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile completeyour_profile_photo"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <div
          className={`flex_login_container ${isDragging ? "dragging" : ""}`}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="login_video">
            <div class="login_video_img">
              <img src={EditProfileImg} alt="EditProfileImg" />
            </div>
            {/* <video autoPlay loop muted className="w-full h-full object-cover">
              <source src={EventVideo} type="video/mp4" />
            </video> */}
          </div>
          <div className="welcome_logo">
            <div className="completeyour_profile">
              <h2 onClick={back} className="back_arrow_signin">
                <div className="back_profile_icon d-none">
                  <img src={Backicon} alt="logo-icon" className="Backicon" />
                </div>
                Complete your profile
              </h2>
            </div>
            <div className="personal_info">
              <h2>
                <span>2</span> Profile photo
              </h2>
              <ProgressBar now={50} />;
            </div>
            <h3 className="profile_label"> Profile photo</h3>
            <Form>
              {previewImage ? (
                <div className="show_profile_pic">
                  <div className="show_profile_pic_flx">
                    <div className="show_profile_img">
                      <img src={previewImage} alt="Emilyprofile" />
                    </div>
                    <div className="show_profile_txt">
                      <p>{`${picUrl}`}</p>
                    </div>
                  </div>
                  <div className="close_btn">
                    <button>
                      <img
                        src={Closecircle}
                        alt="Closecircle"
                        onClick={handleClearSelection}
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className={`form_row ${isDragging ? "dragging-over" : ""}`}
                >
                  <div className="profile_photo_upload">
                    <label for="profile_photo">
                      <div className="empty_img">
                        <img src={EmptyImg} alt="EmptyImg" />
                      </div>

                      <div className="profile_photo_txt">
                        <h4>
                          <span>Click to upload </span>or drag and drop
                        </h4>
                        <p>Max. File size: 15MB</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      id="profile_photo"
                      name="profilePicture"
                      accept="image/jpeg, image/png, image/gif, image/bmp"
                      onChange={handleFileChange}
                      className="d-none"
                    />
                    {formik.touched.profilePicture &&
                      formik.errors.profilePicture && (
                        <small style={{ color: "red" }}>
                          {formik.errors.profilePicture}
                        </small>
                      )}
                  </div>
                </div>
              )}
              <div className="completeyour_profile_btn profile_photo_btn">
                <Button variant="outline-light" onClick={handleSkip}>
                  Skip
                </Button>
                <Button
                  variant="secondary"
                  type="submit"
                  className={previewImage && "is_btn_active"}
                  onClick={formik.handleSubmit}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Profilephoto;
