import React, { useState, useCallback, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Form from "react-bootstrap/Form";
import { Emoji, ThreeLines } from "../utils/icon";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { SendMessages } from "../../api/api";
import { closeCreatePollModal, closeLogin } from "../../redux/Reducers/modalReducer";
import { toast } from "react-toastify";
import { pollValidationSchema } from "../../helpers/validation";
import { useSocket } from "../../context/SocketContext";
import EmojiPicker from "emoji-picker-react";

const Createapoll = ({ setMessages, messages, ...props }) => {
  const { user } = useSelector((state) => state.user);
  const { pollData } = useSelector((state) => state.model);
  const dispatch = useDispatch();
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const clickedFieldId = useRef(null);

  const socket = useSocket();

  const initiallyInputFields = [
    { id: 1, value: "" },
    { id: 2, value: "" },
  ];
  const [optionFields, setOptionFields] = useState(initiallyInputFields);

  const formik = useFormik({
    initialValues: {
      poll_question: "",
      poll_options: [],
      is_multiple_poll_answers_allowed: false,
    },
    validationSchema: pollValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const options = getOptionVals();
      if (options.length < 2) {
        formik.setFieldError(
          "poll_options",
          "At least two poll options are required"
        );
        setSubmitting(false);
        return;
      }
      formik.setFieldValue("poll_options", options);
      formik.setFieldTouched("poll_options", true);
      const dataToSend = {
        sender_id: user?._id,
        tribe_id: pollData,
        type: "poll",
        ...values,
        poll_options: options,
      };
      const result = await SendMessages(dataToSend);
      if (result.statusCode === 201) {
        setMessages([...messages, result.data]);
        socket.emit("send message", result.data);
        dispatch(closeCreatePollModal());
      } else {
        toast.error(result.error);
      }
      setSubmitting(false);
    },
  });

  const handleOptionChange = (event, fieldId) => {
    const newFields = optionFields.map((field) =>
      field.id === fieldId ? { ...field, value: event.target.value } : field
    );
    if (newFields[newFields.length - 1].value === "" && newFields.length > 2) {
      newFields.pop();
    }
    if (newFields[newFields.length - 1].value !== "") {
      newFields.push({ id: newFields.length + 1, value: "" });
    }
    setOptionFields(newFields);
  };

  const handleInputClick = (fieldId) => {
    clickedFieldId.current = fieldId;
  };

  const handleEmojiClick = (emojiObject) => {
    console.log("jas", clickedFieldId.current);
    if (clickedFieldId.current === "poll_question") {
      formik.setFieldValue(
        "poll_question",
        formik.values.poll_question + emojiObject.emoji
      );
    } else {
      setOptionFields((prevFields) =>
        prevFields.map((field) =>
          field.id === clickedFieldId.current
            ? { ...field, value: field.value + emojiObject.emoji }
            : field
        )
      );
    }
    // Ensure emoji picker remains open
    setIsEmojiPickerOpen(true);
  };

  const getOptionVals = () => {
    return optionFields
      .filter((field) => field.value !== "")
      .map((field) => ({ option: field.value }));
  };

  const handleOpenEmoji = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal create_tribe_modal create_a_poll"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a poll</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="name_your_tribe">
            <h3>Ask a question</h3>
            <div className="ask_a_question">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  name="poll_question"
                  placeholder="Your question"
                  value={formik.values.poll_question}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  onClick={() => handleInputClick("poll_question")}
                />
              </Form.Group>
              <div className="ask_question_flx">
                <span onClick={handleOpenEmoji}>
                  <Emoji />
                </span>
              </div>
            </div>
            {formik.touched.poll_question && formik.errors.poll_question && (
              <small className="text-danger">
                {formik.errors.poll_question}
              </small>
            )}
          </div>

          <div className="name_your_tribe">
            <h3>Options</h3>
            {optionFields.map((field) => (
              <div className="ask_a_question add_option" key={field.id}>
                <Form.Group
                  className="mb-3"
                  controlId={`optionField${field.id}`}
                >
                  <Form.Control
                    type="text"
                    placeholder="Add"
                    value={field.value}
                    onChange={(event) => handleOptionChange(event, field.id)}
                    onClick={() => handleInputClick(field.id)}
                  />
                </Form.Group>
                <div className="ask_question_flx">
                  <span onClick={handleOpenEmoji}>
                    <Emoji />
                  </span>
                  <span>
                    <ThreeLines />
                  </span>
                </div>
              </div>
            ))}
            {formik.touched.poll_options && formik.errors.poll_options && (
              <small className="text-danger">
                {formik.errors.poll_options}
              </small>
            )}
          </div>
          {isEmojiPickerOpen && (
            <EmojiPicker
              theme="dark"
              searchDisabled={true}
              onEmojiClick={handleEmojiClick}
            // reactionsDefaultOpen={false}
            />
          )}
          <div className="allow_multiple">
            <div className="allow_multiple_txt">
              <p>Allow multiple answers</p>
            </div>
            <div className="switch_toggle">
              <input
                type="checkbox"
                id="switch"
                name="is_multiple_poll_answers_allowed"
                value={formik.values.is_multiple_poll_answers_allowed}
                checked={formik.values.is_multiple_poll_answers_allowed}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              // onClick={() =>
              //   handleInputClick("is_multiple_poll_answers_allowed")
              // }
              />
              <label htmlFor="switch" onClick={() => {
                formik.setFieldValue("is_multiple_poll_answers_allowed", !formik.values.is_multiple_poll_answers_allowed);
                handleInputClick("is_multiple_poll_answers_allowed");
              }}>Toggle</label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button
              variant="primary"
              className="btn_blue_large"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Send
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Createapoll;
