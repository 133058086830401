import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import { useSelector, useDispatch } from "react-redux";
import { closeCategories } from "../../redux/Reducers/modalReducer";
import {
  genereListing,
  genereListingPostLogin,
  getUserPreferences,
} from "../../api/api";
import {
  resetSelectedCategories,
  updateSelectedCategories,
} from "../../redux/Reducers/queryReducer";
import { sortByName, transformGenres } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.model.isopenCategories);
  const selectedCategories = useSelector(
    (state) => state.query.selectedCategories
  );
  const [genere, setGenere] = useState([]);
  const [selectedGeners, setSelectedGeners] = useState([]);
  const [multiGenere, setMultiGenere] = useState();
  const navigate = useNavigate();
  const { isUserLogedIn, user } = useSelector((state) => state?.user);

  const fetchAllCategories = async () => {
    try {
      let response = null;
      response = await genereListing();
      let genere = response?.data?.data;
      sortByName(genere);
      let multiGenereId = genere.find((obj) => obj.name === "Multi-Genre");
      setGenere(genere);
      setMultiGenere(multiGenereId._id);
    } catch (error) {
      console.error("Error fetching all categories:", error);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, [isUserLogedIn]);

  const handleReset = () => {
    setSelectedGeners([]);
    dispatch(resetSelectedCategories());
  };

  const handleCategoryClick = (id) => {
    if (id === multiGenere) {
      const allSelected = selectedGeners.includes(id);
      if (allSelected) {
        setSelectedGeners([]);
      } else {
        setSelectedGeners([id]);
      }
    } else {
      const isSelected = selectedGeners.includes(id);
      if (isSelected) {
        setSelectedGeners(selectedGeners.filter((g) => g !== id));
      } else {
        setSelectedGeners([...selectedGeners, id]);
      }
    }
  };

  const handleSearch = async () => {
    try {
      // dispatch(updateSelectedCategories(selectedGeners));
      navigate(`/events`, {
        state: { event_genre_type: selectedGeners || "" },
      });
      dispatch(closeCategories());
    } catch (error) {
      console.error("Error sending selected categories to backend:", error);
    }
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => dispatch(closeCategories())}
        className="sign_in_modal categories_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="music_genre categoties_genre">
            <div className="music_genre_head text-start">
              <h3>Music genres</h3>
            </div>
            <div className="select_bage_flx">
              <Badge
                // bg="primary"
                className={
                  selectedGeners.includes(multiGenere)
                    ? "primary is_active"
                    : "primary"
                }
                onClick={() => handleCategoryClick(multiGenere)}
              >
                All
              </Badge>
              {genere &&
                genere.map((genere, key) => (
                  <Badge
                    key={key}
                    // className={genere._id === multiGenere ? "d-none" : ""}
                    bg={
                      selectedGeners.includes(genere._id) ||
                      selectedGeners.includes(multiGenere)
                        ? "primary is_active"
                        : "primary"
                    }
                    onClick={() => handleCategoryClick(genere._id)}
                  >
                    {genere.name}
                  </Badge>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button variant="outline-light" onClick={handleReset}>
              Reset
            </Button>
            <Button
              variant="primary"
              className="btn_blue_large"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Categories;
