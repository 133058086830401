import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import "./layout.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { openCategories } from "../../redux/Reducers/modalReducer";
import { useDispatch } from "react-redux";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [filter, setFilter] = useState("All");

  const handleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="TribeIn " />
        <title>TribeIn</title>
      </Helmet>
      <div className="layout">
        <div className="sidebar_wraper">
          <Sidebar
            sidebarVisible={sidebarVisible}
            setSidebarVisible={setSidebarVisible}
            handleSidebar={handleSidebar}
          />
          <div
            className={`main_wraper ${sidebarVisible ? "remove_margin" : ""}`}
          >
            <div className="header">
              <Header handleSidebar={handleSidebar} />
            </div>
            {children}
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
