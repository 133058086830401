import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import Backicon from "../../assets/img/Backicon.svg";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
// import Tribesmember from "../../assets/img/Tribesmember.png";
// import Tribesmembertwo from "../../assets/img/Tribesmembertwo.png";
// import Tribesmemberthree from "../../assets/img/Tribesmemberthree.png";
// import Tribesmemberfour from "../../assets/img/Tribesmemberfour.png";
// import TribesmemberFive from "../../assets/img/TribesmemberFive.png";
// import Tribesmembersix from "../../assets/img/Tribesmembersix.png";
// import Tribesmemberseven from "../../assets/img/Tribesmemberseven.png";
// import Tribesmembereight from "../../assets/img/Tribesmembereight.png";
// import Tribesmembernine from "../../assets/img/Tribesmembernine.png";
// import Tribesmemberten from "../../assets/img/Tribesmemberten.png";
// import Tribesmembereleven from "../../assets/img/Tribesmembereleven.png";
import { getTribes, joinTribes } from "../../api/api";
import {
  openUpdatePreferences,
  closeTribe,
  openCreateTribe,
  openSkipTribeModal,
  openMaximumTribesJoinedModal,
  openTribeExitedMaximumTimes,
} from "../../redux/Reducers/modalReducer";
import { toast } from "react-toastify";
import {
  contains2024,
  // findTribeIdByUserId,
  userExistsInTribes,
} from "../../helpers/utils";
import userprofileimage from "../../assets/img/profile-pic.svg";

const Tribes = (props) => {
  const { data } = useSelector((state) => state?.model);
  const { user } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [tribe, setTribe] = useState([]);
  const [selectedTribeId, setSelectedTribeId] = useState(null);
  const userLocation = useSelector((state) => state.user.userLocation);
  const navigate = useNavigate();
  const eventId = data.eventId;
  const today = new Date();
  const currentYear = today.getFullYear();
  // const [userTribeJoinedId, setUserTribeJoinedId] = useState(null);

  const handleSkip = () => {
    dispatch(openSkipTribeModal());
  };
  useEffect(() => {
    fetchAllTribes();
  }, [userLocation, eventId]);

  const fetchAllTribes = async () => {
    const radius = 10;

    const requestData = {
      event_id: eventId,
      search_query: "",
      page: 1,
      limit: 10,
      sortBy: "createdAt",
      order: -1,
    };

    try {
      const response = await getTribes(requestData);
      setTribe(response.data.docs);
      if (response.data.docs.length > 0) {
        setSelectedTribeId(response.data.docs[0]._id);
      }
    } catch (error) {
      console.error("Error fetching tribes:", error);
    }
  };

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex;
    const selectedTribe = tribe[currentSlideIndex];
    setSelectedTribeId(selectedTribe ? selectedTribe._id : null);
  };

  const joinTribe = async () => {
    if (selectedTribeId) {
      try {
        let userExists = userExistsInTribes(tribe, user._id);
        if (userExists) {
          dispatch(openMaximumTribesJoinedModal());
          return;
        }
        const response = await joinTribes({ tribe_id: selectedTribeId });
        console.log(response, "response");
        if (response.statusCode === "400") {
          dispatch(openTribeExitedMaximumTimes());
        } else if (response.statusCode === 201) {
          toast.success(response.message);
          dispatch(closeTribe());
          navigate("/myTribes");
        } else {
          toast.error(response.error);
        }
      } catch (error) {
        console.error("Error joining tribe:", error);
      }
    } else {
      console.error("No tribe selected");
    }
  };

  const handleCreateTribe = () => {
    dispatch(openCreateTribe(data));
    dispatch(closeTribe());
  };

  // const tribe_id = findTribeIdByUserId(tribe, user._id);

  return (
    <>
      <Modal
        //    show={isOpen}
        {...props}
        className="sign_in_modal signin_inner_modal categories_modal tribein_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="back_arrow_signin">
              <div className="back_profile_icon">
                <img src={Backicon} alt="logo-icon" className="Backicon" />
              </div>{" "}
              Sign in
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <h4>TribeIn</h4>

          <Swiper
            className="mySwiper schedule_slider"
            modules={[Pagination]}
            pagination={{ clickable: true }}
            slidesPerView={1}
            onSlideChange={handleSlideChange}
          >
            {tribe.map((tribeItem, index) => (
              <SwiperSlide key={tribeItem._id}>
                <div className="tribesmember_slide">
                  <div className="tribesmember_slide_txt">
                    <p>
                      🌴 Join the Tribe at{" "}
                      <span>
                        {tribeItem?.event_name}{" "}
                        {contains2024(tribeItem.event_name) ? "" : currentYear}
                      </span>
                      ! 🌴{" "}
                    </p>
                    <p>
                      <span>Age-Group:</span> {tribeItem?.age_group}
                    </p>
                  </div>

                  {/* <div className="tribesmember_slide_img">
                      <img src={Tribesmember} alt='Tribesmember' />
                       <p>{tribeItem.tribeUsers.length} Members</p>
                  </div> */}

                  {/* for dynamic picture show */}

                  <div className="multiple_tribe_member">
                    <ul>
                      {tribeItem?.tribeUsers?.length &&
                        tribeItem?.tribeUsers
                          .slice(0, 11)
                          .map((item, index) => (
                            <>
                              <li className="multiple_tribe_member_one">
                                <img
                                  src={
                                    item?.user?.profile_pic || userprofileimage
                                  }
                                  alt="user_profile_pic"
                                />
                              </li>
                            </>
                          ))}
                      <li className="multiple_tribe_member_tvel">
                        <div className={`plus_member`}>
                          <h3
                            className={`${
                              tribeItem?.tribeUsers.length <= 11 && "d-none"
                            }`}
                          >
                            +{tribeItem?.tribeUsers.length - 11}
                          </h3>
                        </div>
                        <div className="plus_other_member">
                          <span>
                            {tribeItem?.tribeUsers.length <= 1
                              ? `${tribeItem?.tribeUsers.length} Member`
                              : `${tribeItem?.tribeUsers.length} Members`}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="multiple_tribe_member">
                    <ul>
                      <li className="multiple_tribe_member_one">
                        <img src={Tribesmember} />
                      </li>
                      <li className="multiple_tribe_member_two">
                        <img src={Tribesmembertwo} />
                      </li>
                      <li className="multiple_tribe_member_three">
                        <img src={Tribesmemberthree} />
                      </li>
                      <li className="multiple_tribe_member_four">
                        <img src={Tribesmemberfour} />
                      </li>
                      <li className="multiple_tribe_member_five">
                        <img src={TribesmemberFive} />
                      </li>
                      <li className="multiple_tribe_member_six">
                        <img src={Tribesmembersix} />
                      </li>
                      <li className="multiple_tribe_member_seven">
                        <img src={Tribesmemberseven} />
                      </li>
                      <li className="multiple_tribe_member_eight">
                        <img src={Tribesmembereight} />
                      </li>
                      <li className="multiple_tribe_member_nine">
                        <img src={Tribesmembernine} />
                      </li>
                      <li className="multiple_tribe_member_ten">
                        <img src={Tribesmemberten} />
                      </li>
                      <li className="multiple_tribe_member_elevn">
                        <img src={Tribesmembereleven} />
                      </li>
                      <li className="multiple_tribe_member_tvel">
                        <div className={`plus_member`}>
                          <h3
                            className={`${
                              tribeItem?.tribeUsers.length <= 11 && "d-none"
                            }`}
                          >
                            +{tribeItem?.tribeUsers.length - 11}
                          </h3>
                        </div>
                        <div className="plus_other_member">
                          <span>
                            {tribeItem?.tribeUsers.length <= 1
                              ? `${tribeItem?.tribeUsers.length} Member`
                              : `${tribeItem?.tribeUsers.length} Members`}
                          </span>
                        </div>
                      </li>
                    </ul> */}
                  {/* </div> */}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="edit_your_age">
            <Link
              onClick={() =>
                dispatch(openUpdatePreferences({ eventId: data.eventId }))
              }
            >
              Edit your age and gender preferences
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button onClick={handleCreateTribe} variant="outline-light">
              CREATE TRIBE
            </Button>
            <Button
              variant="primary"
              className="btn_blue_large"
              onClick={joinTribe}
              // disabled={tribe_id === tribeItem?._id}
            >
              Join tribe
            </Button>
          </div>
          <div className="skip_btn" onClick={() => dispatch(closeTribe())}>
            <Link to="#" onClick={handleSkip}>
              Skip
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tribes;
