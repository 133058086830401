import React, { useEffect, useState, useRef } from "react";
import Videocard from "../Component/videocard/videocard";
import { searchEvents } from "../api/api";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { TailSpin } from "react-loader-spinner";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { setSearchQuery } from "../redux/Reducers/queryReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openFilterModal } from "../redux/Reducers/modalReducer";

import {
  FillterIcon,
  Headerserach,
  SearchCross,
} from "../../src/Component/utils/icon";

const Searchempty = () => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const quries = useLocation();
  const [hasMore, setHasMore] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { searchQuery } = useSelector((state) => state?.query);
  const [searchValue, setSearchValue] = useState(searchQuery || "");

  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 6;

  const scrollableDivRef = useRef(null);

  let payload = {
    page: currentPage,
    limit: limit,
    sort_by: "event_start_date",
    order: 1,
    search_query: quries?.state?.search_query || "",
    location: quries?.state?.selectedLocation || "",
    event_start_date: quries?.state?.event_start_date || "",
    event_end_date: quries?.state?.event_end_date || "",
    event_genre_type: quries?.state?.event_genre_type || "",
    event_type: "festival",
    time_query: quries?.state?.selectedDates || "",
  };

  const fetchEvents = async () => {
    console.log("in");
    try {
      setLoading(true);
      payload.page = 1;
      const response = await searchEvents(payload);
      if (!response || !response.data || !response.data.docs) {
        throw new Error("Invalid response from server");
      }
      setLoading(false);
      setEventData(response?.data?.docs);
      setHasMore(eventData.length < response.data.totalDocs);
      setTotalDocs(response.data.totalDocs);
    } catch (error) {
      console.log("Error fetching events:", error);
      setLoading(false);
      // Handle error state or notify the user
    }
  };

  const handleSearchFestival = (e) => {
    e.preventDefault();
    dispatch(setSearchQuery(searchValue));
    navigate(`/events`, {
      state: { search_query: searchValue || "" },
    });
  };

  useEffect(() => {
    fetchEvents();
  }, [quries]);

  useEffect(() => {
    const debounceTime = setTimeout(() => {
      dispatch(setSearchQuery(searchValue));
    }, 4000);
    return () => {
      clearTimeout(debounceTime);
    };
  });

  const fetchMoreEvents = async () => {
    try {
      setLoading(true);
      const response = await searchEvents(payload);
      if (!response || !response.data || !response.data.docs) {
        throw new Error("Invalid response from server");
      }

      setLoading(false);
      setEventData([...eventData, ...response.data.docs]);
      setHasMore(eventData.length < response.data.totalDocs);
      setTotalDocs(response.data.totalDocs);
    } catch (error) {
      console.log("Error fetching events:", error);
      setLoading(false);
      // Handle error state or notify the user
    }
  };

  const loadMore = () => {
    setHasMore(false);
    if (!loading && eventData.length < totalDocs && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
      fetchMoreEvents();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(openFilterModal());
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleShowSearchBar = () => {
    setShowSearch(!showSearch);
  };

  return (
    <>
      <div className="form_warper searchNewControllerMobile">
        <Form onSubmit={(e) => handleSearchFestival(e)}>
          <Form.Control
            initialvalue-={searchQuery}
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleChange}
            className={showSearch ? "active" : ""}
          />
          <div className="header_serch " onClick={() => handleShowSearchBar()}>
            <Headerserach />
          </div>
          <div
            className="seacrh_corss hidden"
            onClick={() => setSearchValue("")}
          >
            <SearchCross />
          </div>
          <button onClick={handleSearch} type="button" className="filter_btn">
            {/* <span className="filtter_count">4</span> */}
            <FillterIcon />
          </button>
        </Form>
      </div>

      <div
        className="show_result"
        ref={scrollableDivRef}
        style={{ height: "100vh", overflowY: "scroll", width: "100%" }}
      >
        <div className="inner_wraper my_event_inner_wrapper pb-0 ">
          <div className="back_profile">
            <div className="back_profile_txt" style={{ width: "100%" }}>
              {currentPage === 1 && loading ? (
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <TailSpin
                      visible={true}
                      height="80"
                      width="80"
                      color="#fff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass="d-flex"
                    />
                  </Col>
                </Row>
              ) : eventData?.length ? (
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  loader={
                    <span className="text-white" key={0}>
                      Loading ...
                    </span>
                  }
                  useWindow={false}
                  getScrollParent={() => scrollableDivRef.current}
                  className=""
                >
                  <div className="search_result">
                    {eventData?.map((data, index) => (
                      <Videocard
                        key={index}
                        eventData={data}
                        genereRequired="false"
                      />
                    ))}
                  </div>
                  {currentPage !== 1 && loading && (
                    <Row className="justify-content-center">
                      <Col xs="auto">
                        <TailSpin
                          visible={true}
                          height="80"
                          width="80"
                          color="#fff"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass="d-flex"
                        />
                      </Col>
                    </Row>
                  )}
                </InfiniteScroll>
              ) : (
                <div className="no_result">
                  <p className="">No results were found for this search 😓.</p>
                  <p>Please try again</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Searchempty;
