import React from "react";
import { Helmet } from "react-helmet";

const Payment = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Complete your purchase securely and conveniently. Buy festival tickets online, access exclusive events, and join your tribe with TribeIn."
        />
        <title>Secure Payment: Buy Festival Tickets Online | TribeIn</title>
      </Helmet>
      <div
        style={{
          // width: "100vw",
          height: "100vh",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          // marginLeft: "20px",
          fontSize: "30px",
          color: "white",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Coming soon.
      </div>
    </>
  );
};

export default Payment;
