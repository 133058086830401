import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Login from "./login/login";
import Signin from "./signin/signin";
import OtpVerify from "./signin/OtpVerify";
import Completeyourprofile from "./completeyourprofile/completeyourprofile";
import Profilephoto from "./profilephoto/profilephoto";
import Preferences from "./preferences/preferences";
import Socialmedia from "./socialmedia/socialmedia";
import Integrations from "./integrations/integrations";
import Verification from "./verification/verification";
import {
  closeSettingConfirmation,
  closeConfirmationModal,
  closeUpdateProfilePic,
  closeUpdatePreferences,
  closeUpdateProflie,
  closeIntegrationModal,
  closeLogin,
  closeOtpModal,
  closePreferencesModal,
  closeProfileDetails,
  closeSignup,
  closeSocialConnectionModal,
  closeUploadPic,
  closeCaptchaModal,
  closeCreateTribe,
  closeTribe,
  closeFilterModal,
  closeUpdateIntegrationModal,
  closeUpdateSocialMediaModal,
  closeReportUserModal,
  closeSuccessfullCompletionModal,
  closeCreatePollModal,
  closeUpdatePhoneNumberModal,
  closeUpdatePhoneVerifyOtp,
  closeExitTribeConfirmationModal,
  closeFeedbackModal,
  closePhoneBookModal,
  closeMaximumTribesJoinedModal,
  closeHideMyLocationFromUser,
  closeTribeExitedMaximumTimes,
  closeSkipTribeModal,
  closeToggleButtonModal,
} from "../redux/Reducers/modalReducer";
import UpdateProfile from "./updateProfile/UpdateProfile";
import UpdatePreferences from "./updatePreferences/UpdatePreferences";
import UpdateProfilePic from "./updateProfilePic/UpdateProfilePic";
import ConfirmationModal from "./confirmationmodal/ConfirmationModal";
import Createtribe from "../modals/createtribe/createtribe";
import Tribes from "./tribes/tribes";
import Fillter from "../Component/fillter/fillter";
import UpdateIntegrations from "./updateIntegrations/UpdateIntegrations";
import UpdateSocialMediaConnections from "./updateSocailMediaConnections/UpdateSocialMediaConnections";
import Reportuser from "./reportuser/reportuser";
import Requestsuccessfullysubmitted from "../Component/requestsuccessfullysubmitted/requestsuccessfullysubmitted";
import Createapoll from "../Component/createapoll/createapoll";
import UpdatePhoneNumber from "./updatePhone/UpdatePhoneNumber";
import UpdatePhoneOtpVerify from "./updatePhone/UpdatePhoneOtpVerify";
import ExitTribeConfirmationModal from "./exittribeconfirmation/ExitTribeConfirmationModal";
import Yourfeedback from "./yourfeedback/yourfeedback";
import SettingConfirmationModal from "./settingConfirmation/SettingConfirmationModal";
import SkipTribeModal from "./skiptribe/SkipTribeModal";
import PhoneBook from "./MessageForPhonebook/PhoneBook";
import MaximumTribesJoined from "./MaximumTribesJoined/MaximumTribesJoined";
import HideMyLocationModal from "./hidelocationconfirmation/HideLocationConfiemation";
import HideMyLocationFromUserModal from "./hidelocationconfirmation/HideLocationConfiemation";
import UserDetails from "./userdetails/UserDetails";
import TribeExitedMaximumTimes from "./TribeExitedMaximumTimes/TribeExitedMaximumTimes";
import ToggleButtonModal from "./togglebuttonmodal/ToggleButtonModal";
const IndexModal = () => {
  const dispatch = useDispatch();
  const {
    isopenToggleButtonModal,
    isopenTribeExitedMaximumTimes,
    isopenHideMyLocationFromUser,
    isopenMaximumTribesJoinedModal,
    isopenPhoneBookModal,
    isopenSkipTribeModal,
    isopenSettingConfirmationModal,
    isopenConfirmationModal,
    isopenUpdateProfilePic,
    isopenUpdatePreferences,
    isopenUpdateProfile,
    isopenSignup,
    isopenProfilePic,
    isopenOtpModal,
    isopenPreferencesModal,
    isopenIntegrationModal,
    isopenSocialConnectionModal,
    isopenLogin,
    isopenProfileDetails,
    isopenCaptchaModel,
    isopenCreateTribe,
    isopenTribe,
    isopenfilterModal,
    isopenUpdateSocialMedia,
    isopenUpdateIntegrationModal,
    isopenReportUserModal,
    isopenSuccessfullCompletionModal,
    isOpenCreatePollModal,
    isopenUpdatePhoneNumberModal,
    isopenUpdatePhoneVerifyOtp,
    isopenExitTribeConfirmation,
    isopenFeedbackModal,
  } = useSelector((state) => state?.model);

  return (
    <>
      {isopenSignup && (
        <Login show={isopenSignup} onHide={() => dispatch(closeSignup())} />
      )}
      {isopenLogin && (
        <Signin show={isopenLogin} onHide={() => dispatch(closeLogin())} />
      )}
      {isopenOtpModal && (
        <OtpVerify
          show={isopenOtpModal}
          onHide={() => dispatch(closeOtpModal())}
        />
      )}
      {/* {isopenProfileDetails && (
        <Completeyourprofile
          show={isopenProfileDetails}
          onHide={() => dispatch(closeProfileDetails())}
        />
      )} */}
      {isopenProfileDetails && (
        <UserDetails
          show={isopenProfileDetails}
          onHide={() => dispatch(closeProfileDetails())}
        />
      )}
      {isopenProfilePic && (
        <Profilephoto
          show={isopenProfilePic}
          onHide={() => dispatch(closeUploadPic())}
        />
      )}
      {isopenPreferencesModal && (
        <Preferences
          show={isopenPreferencesModal}
          onHide={() => dispatch(closePreferencesModal())}
        />
      )}
      {isopenSocialConnectionModal && (
        <Socialmedia
          show={isopenSocialConnectionModal}
          onHide={() => dispatch(closeSocialConnectionModal())}
        />
      )}
      {isopenIntegrationModal && (
        <Integrations
          show={isopenIntegrationModal}
          onHide={() => dispatch(closeIntegrationModal())}
        />
      )}
      {isopenCaptchaModel && (
        <Verification
          show={isopenCaptchaModel}
          onHide={() => dispatch(closeCaptchaModal())}
        />
      )}
      {isopenUpdateProfile && (
        <UpdateProfile
          show={isopenUpdateProfile}
          onHide={() => dispatch(closeUpdateProflie())}
        />
      )}
      {isopenUpdatePreferences && (
        <UpdatePreferences
          show={isopenUpdatePreferences}
          onHide={() => dispatch(closeUpdatePreferences())}
        />
      )}
      {isopenUpdateProfilePic && (
        <UpdateProfilePic
          show={isopenUpdateProfilePic}
          onHide={() => dispatch(closeUpdateProfilePic())}
        />
      )}
      {isopenConfirmationModal && (
        <ConfirmationModal
          show={isopenConfirmationModal}
          onHide={() => dispatch(closeConfirmationModal())}
        />
      )}

      {isopenTribe && (
        <Tribes show={isopenTribe} onHide={() => dispatch(closeTribe())} />
      )}
      {isopenCreateTribe && (
        <Createtribe
          show={isopenCreateTribe}
          onHide={() => dispatch(closeCreateTribe())}
        />
      )}
      {isopenfilterModal && (
        <Fillter
          show={isopenfilterModal}
          onHide={() => dispatch(closeFilterModal())}
        />
      )}
      {isopenUpdateIntegrationModal && (
        <UpdateIntegrations
          show={isopenUpdateIntegrationModal}
          onHide={() => dispatch(closeUpdateIntegrationModal())}
        />
      )}
      {isopenUpdateSocialMedia && (
        <UpdateSocialMediaConnections
          show={isopenUpdateSocialMedia}
          onHide={() => dispatch(closeUpdateSocialMediaModal())}
        />
      )}
      {isopenReportUserModal && (
        <Reportuser
          show={isopenReportUserModal}
          onHide={() => dispatch(closeReportUserModal())}
        />
      )}
      {isopenSuccessfullCompletionModal && (
        <Requestsuccessfullysubmitted
          show={isopenSuccessfullCompletionModal}
          onHide={() => dispatch(closeSuccessfullCompletionModal())}
        />
      )}
      {isOpenCreatePollModal && (
        <Createapoll
          show={isOpenCreatePollModal}
          onHide={() => dispatch(closeCreatePollModal())}
        />
      )}
      {isopenUpdatePhoneNumberModal && (
        <UpdatePhoneNumber
          show={isopenUpdatePhoneNumberModal}
          onHide={() => dispatch(closeUpdatePhoneNumberModal())}
        />
      )}
      {isopenUpdatePhoneVerifyOtp && (
        <UpdatePhoneOtpVerify
          show={isopenUpdatePhoneVerifyOtp}
          onHide={() => dispatch(closeUpdatePhoneVerifyOtp())}
        />
      )}
      {isopenExitTribeConfirmation && (
        <ExitTribeConfirmationModal
          show={isopenExitTribeConfirmation}
          onHide={() => dispatch(closeExitTribeConfirmationModal())}
        />
      )}
      {isopenFeedbackModal && (
        <Yourfeedback
          show={isopenFeedbackModal}
          onHide={() => dispatch(closeFeedbackModal())}
        />
      )}
      {isopenSettingConfirmationModal && (
        <SettingConfirmationModal
          show={isopenSettingConfirmationModal}
          onHide={() => dispatch(closeSettingConfirmation())}
        />
      )}
      {isopenSkipTribeModal && (
        <SkipTribeModal
          show={isopenSkipTribeModal}
          onHide={() => dispatch(closeSkipTribeModal())}
        />
      )}
      {isopenPhoneBookModal && (
        <PhoneBook
          show={isopenPhoneBookModal}
          onHide={() => dispatch(closePhoneBookModal())}
        />
      )}
      {isopenMaximumTribesJoinedModal && (
        <MaximumTribesJoined
          show={isopenMaximumTribesJoinedModal}
          onHide={() => dispatch(closeMaximumTribesJoinedModal())}
        />
      )}
      {isopenHideMyLocationFromUser && (
        <HideMyLocationFromUserModal
          show={isopenHideMyLocationFromUser}
          onHide={() => dispatch(closeHideMyLocationFromUser())}
        />
      )}
      {isopenTribeExitedMaximumTimes && (
        <TribeExitedMaximumTimes
          show={isopenTribeExitedMaximumTimes}
          onHide={() => dispatch(closeTribeExitedMaximumTimes())}
        />
      )}
      {isopenToggleButtonModal && (
        <ToggleButtonModal
          show={isopenToggleButtonModal}
          onHide={() => dispatch(closeToggleButtonModal())}
        />
      )}
    </>
  );
};

export default IndexModal;
