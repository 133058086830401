import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import RangeSlider from "react-bootstrap-range-slider";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { DATE_RANGE } from "../../helpers/constant";
import { resetDateAndLocation } from "../../redux/Reducers/queryReducer";
import { useDispatch, useSelector } from "react-redux";
import { closeFilterModal } from "../../redux/Reducers/modalReducer";
import DatePicker from "react-datepicker";
import { formatFilterDate } from "../../helpers/utils";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";

const Fillter = (props) => {
  const dispatch = useDispatch();
  const { selectedDateAndLocation } = useSelector((state) => state?.query);
  const [value2, setValue2] = React.useState(75);
  const [selectedDates, setSelectedDates] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [show, setShow] = useState(false);
  const [selectedCustomDates, setSelectedCustomDates] = useState({
    from: "",
    to: "",
  });
  const [errorTo, setErrorTo] = useState(false);
  const [errorFrom, setErrorFrom] = useState(false);
  const navigate = useNavigate();

  const handlePlace = (place) => {
    const address = {
      name: place.formatted_address,
      loc: {
        type: "point",
        coordinates: [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ],
      },
    };
    setSelectedLocation(address?.loc?.coordinates);
  };

  useEffect(() => {
    setSelectedDates(selectedDateAndLocation?.selectedDates ?? "");
    setSelectedCustomDates(selectedDateAndLocation?.selectedCustomDates);
    setSelectedLocation(selectedDateAndLocation?.selectedLocation);
  }, [selectedDateAndLocation]);

  const today = new Date();

  const handleDateChange = (date) => {
    if (selectedDates === date.value) {
      setSelectedDates("");
    } else {
      setSelectedDates(date.value);
    }
  };

  const handleCustomDateChange = (dateType, date) => {
    if (dateType === "from") {
      if (
        selectedCustomDates?.to &&
        formatFilterDate(date) > selectedCustomDates?.to
      ) {
        setErrorFrom(true);
        return;
      }
    } else if (dateType === "to") {
      if (
        selectedCustomDates?.from &&
        formatFilterDate(date) < selectedCustomDates?.from
      ) {
        setErrorTo(true);
        return;
      }
    }
    setErrorTo(false);
    setErrorFrom(false);
    setSelectedCustomDates((prevDates) => ({
      ...prevDates,
      [dateType]: formatFilterDate(date),
    }));
  };

  const handleSearch = async () => {
    // dispatch(
    //   setDateAndLocation({
    //     selectedDates,
    //     selectedLocation,
    //     selectedCustomDates,
    //   })
    // );
    navigate(`/events`, {
      state: {
        selectedLocation: selectedLocation || "",
        selectedDates: selectedDates || "",
        event_start_date: selectedCustomDates?.from || "",
        event_end_date: selectedCustomDates?.to || "",
      },
    });
    dispatch(closeFilterModal());
  };

  const handleReset = () => {
    setSelectedCustomDates([]);
    setSelectedLocation("");
    setSelectedDates("");
    dispatch(resetDateAndLocation());
  };

  const handleShowCustomDates = () => {
    setShow(!show);
  };
  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter By:</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="more_info_accordian fillter_accordian mt-0">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Date Range </Accordion.Header>
                <Accordion.Body>
                  <div className="select_bage_flx">
                    <div className="select_bage_flx">
                      {DATE_RANGE?.map((date, key) => (
                        <>
                          <Badge
                            key={key}
                            bg={
                              selectedDates?.includes(date.value)
                                ? "primary is_active"
                                : "primary"
                            }
                            onClick={() => handleDateChange(date)}
                            style={{ cursor: "pointer" }}
                          >
                            {date.label}
                          </Badge>
                        </>
                      ))}
                      <Badge
                        bg={show ? "primary is_active" : "primary"}
                        onClick={handleShowCustomDates}
                      >
                        Custom
                      </Badge>
                    </div>
                  </div>
                  {show && (
                    <div className="choose_date">
                      <div className="form_row">
                        <label htmlFor="from" style={{ color: "#FFFFFF" }}>
                          From
                        </label>
                        <DatePicker
                          id="from"
                          dateFormat="MM/dd/yyyy"
                          value={selectedCustomDates?.from}
                          onChange={(date) =>
                            handleCustomDateChange("from", date)
                          }
                          placeholderText="Choose date"
                          minDate={new Date(1960, 0, 1)}
                          todayButton={"Today"}
                        />
                        {errorFrom && (
                          <small className="text-danger">
                            Event from date should be less than event to date.
                          </small>
                        )}
                      </div>
                      <div className="form_row">
                        <label htmlFor="to" style={{ color: "#FFFFFF" }}>
                          To
                        </label>
                        <DatePicker
                          id="to"
                          dateFormat="MM/dd/yyyy"
                          value={selectedCustomDates?.to}
                          onChange={(date) =>
                            handleCustomDateChange("to", date)
                          }
                          placeholderText="Choose date"
                          minDate={new Date(1960, 0, 1)}
                          todayButton={"Today"}
                        />
                        {errorTo && (
                          <small className="text-danger">
                            Event to date should be greater than event from
                            date.
                          </small>
                        )}
                      </div>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="d-none">
                <Accordion.Header>Price Range</Accordion.Header>
                <Accordion.Body>
                  <div className="range_slider_tip">
                    <RangeSlider
                      value={value2}
                      onChange={(e) => setValue2(e.target.value)}
                      tooltipPlacement="bottom"
                      tooltip="on"
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="d-none">
                <Accordion.Header>Venues</Accordion.Header>
                <Accordion.Body>
                  <div className="select_bage_flx">
                    <Badge bg="primary" className="">
                      Family Friendly
                    </Badge>
                    <Badge bg="primary" className="">
                      Beach
                    </Badge>
                    <Badge bg="primary" className="">
                      Downtown
                    </Badge>
                    <Badge bg="primary" className="">
                      Indoors
                    </Badge>
                    <Badge bg="primary" className="">
                      Ski Resort
                    </Badge>
                    <Badge bg="primary" className="">
                      Island
                    </Badge>
                    <Badge bg="primary" className="">
                      Cruise Ship
                    </Badge>
                    <Badge bg="primary" className="">
                      Showcase
                    </Badge>
                    <Badge bg="primary" className="">
                      Conference
                    </Badge>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Location</Accordion.Header>
                <Accordion.Body>
                  <div className="form_row form_location">
                    <div className="form_row_icon">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.99993 5.29515C7.12732 5.29515 6.41993 6.00254 6.41993 6.87515C6.41993 7.74776 7.12732 8.45515 7.99993 8.45515C8.87254 8.45515 9.57993 7.74776 9.57993 6.87515C9.57993 6.00254 8.87254 5.29515 7.99993 5.29515ZM5.41993 6.87515C5.41993 5.45026 6.57504 4.29515 7.99993 4.29515C9.42483 4.29515 10.5799 5.45026 10.5799 6.87515C10.5799 8.30005 9.42483 9.45515 7.99993 9.45515C6.57504 9.45515 5.41993 8.30005 5.41993 6.87515Z"
                          fill="#E6E9FF"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.00207 1.83515C5.72421 1.83361 3.49626 3.1554 2.90082 5.77254C2.19619 8.88598 4.12533 11.586 5.94005 13.335C7.09372 14.4425 8.90704 14.4411 10.0527 13.3354L10.0536 13.3345C11.8749 11.5855 13.8037 8.8921 13.0989 5.77887C12.5066 3.16164 10.28 1.83669 8.00207 1.83515ZM14.0742 5.55808C13.3599 2.402 10.6564 0.836939 8.00274 0.835146C5.34899 0.833352 2.64364 2.39483 1.92569 5.5509C1.09722 9.21057 3.3811 12.2576 5.24627 14.0552C6.78582 15.5337 9.2125 15.5357 10.7467 14.0553C12.6187 12.2575 14.9027 9.21786 14.0742 5.55808Z"
                          fill="#E6E9FF"
                        ></path>
                      </svg>
                    </div>

                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      style={{ width: "100%" }}
                      onPlaceSelected={(place) => handlePlace(place)}
                      defaultValue={selectedLocation}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="d-none">
                <Accordion.Header>Event Size</Accordion.Header>
                <Accordion.Body>
                  <div className="select_bage_flx">
                    <Badge bg="primary" className="">
                      Small (10K OR LESS)
                    </Badge>
                    <Badge bg="primary" className="">
                      Medium (11K-25K)
                    </Badge>
                    <Badge bg="primary" className="">
                      Large (26K-50K)
                    </Badge>
                    <Badge bg="primary" className="">
                      Mega (50K+)
                    </Badge>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="d-none">
                <Accordion.Header>Number of Days </Accordion.Header>
                <Accordion.Body>
                  <div className="select_bage_flx">
                    <Badge bg="primary" className="">
                      1-Day
                    </Badge>
                    <Badge bg="primary" className="">
                      2-Day
                    </Badge>
                    <Badge bg="primary" className="">
                      3-Day
                    </Badge>
                    <Badge bg="primary" className="">
                      4-Day
                    </Badge>
                    <Badge bg="primary" className="">
                      5-Day
                    </Badge>
                    <Badge bg="primary" className="">
                      6+ Days
                    </Badge>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button variant="outline-light" onClick={handleReset}>
              Reset
            </Button>
            <Button
              variant="primary"
              className="btn_blue_large"
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Fillter;
