import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSignup } from "../redux/Reducers/modalReducer";
import Layout from "../Component/Layout/Layout";
import { logout } from "../redux/Reducers/userReducer";

function Protected() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  if (!token) {
    dispatch(logout());
    dispatch(openSignup());
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default Protected;
