import { createSlice } from "@reduxjs/toolkit";
import TribeExitedMaximumTimes from "../../modals/TribeExitedMaximumTimes/TribeExitedMaximumTimes";

const modelSlice = createSlice({
  name: "model",
  initialState: {
    isopenToggleButtonModal: false,
    isopenTribeExitedMaximumTimes: false,
    hideLocationFrom: null,
    pollData: null,
    data: null,
    reportUserDetails: null,
    isUserLogedIn: false,
    isopen: false,
    isopenLogin: false,
    isopenSignup: false,
    isopenProfileDetails: false,
    isopenProfilePic: false,
    isopenSignupWithPhone: false,
    isopenOtpModal: false,
    isopenPreferencesModal: false,
    isopenSocialConnectionModal: false,
    isopenIntegrationModal: false,
    isopenCaptchaModel: false,
    isopenCategories: false,
    isopenUpdateProfile: false,
    isopenUpdatePreferences: false,
    isopenUpdateProfilePic: false,
    isopenTribe: false,
    isopenCreateTribe: false,
    isopenConfirmationModal: false,
    isopenfilterModal: false,
    isopenUpdateSocialMedia: false,
    isopenUpdateIntegrationModal: false,
    isopenReportUserModal: false,
    isopenSuccessfullCompletionModal: false,
    isOpenCreatePollModal: false,
    isopenUpdatePhoneNumberModal: false,
    isopenUpdatePhoneVerifyOtp: false,
    isopenExitTribeConfirmation: false,
    isopenFeedbackModal: false,
    isopenSettingConfirmationModal: false,
    isopenSkipTribeModal: false,
    isopenPhoneBookModal: false,
    isopenMaximumTribesJoinedModal: false,
    isopenHideMyLocationFromUser: false,
    isopenHideMyLocationFromAllUser: false,
  },
  reducers: {
    openModal: (state, action) => {
      state.data = action.payload || null;
      state.isopen = true;
    },
    closeModal: (state, action) => {
      state.isopen = false;
    },
    openLogin: (state, action) => {
      state.data = action.payload || null;
      state.isopenLogin = true;
    },
    closeLogin: (state, action) => {
      state.isopenLogin = false;
    },
    openSignup: (state, action) => {
      state.data = action.payload || null;
      state.isopenSignup = true;
    },
    closeSignup: (state, action) => {
      state.isopenSignup = false;
    },
    openSignupWithPhone: (state, action) => {
      state.data = action.payload || null;
      state.isopenSignupWithPhone = true;
    },
    closeSignupWithPhone: (state, action) => {
      state.isopenSignupWithPhone = false;
    },
    openProfileDetails: (state, action) => {
      state.data = action.payload || null;
      state.isopenProfileDetails = true;
    },
    closeProfileDetails: (state, action) => {
      state.isopenProfileDetails = false;
    },
    openUploadPic: (state, action) => {
      state.data = action.payload || null;
      state.isopenProfilePic = true;
    },
    closeUploadPic: (state, action) => {
      state.isopenProfilePic = false;
    },
    openOtpModal: (state, action) => {
      state.data = action.payload || null;
      state.isopenOtpModal = true;
    },
    closeOtpModal: (state, action) => {
      state.isopenOtpModal = false;
    },
    openPreferencesModal: (state, action) => {
      state.data = action.payload || null;
      state.isopenPreferencesModal = true;
    },
    closePreferencesModal: (state, action) => {
      state.isopenPreferencesModal = false;
    },
    openSocialConnectionModal: (state, action) => {
      state.data = action.payload || null;
      state.isopenSocialConnectionModal = true;
    },
    closeSocialConnectionModal: (state, action) => {
      state.isopenSocialConnectionModal = false;
    },
    openIntegrationModal: (state, action) => {
      state.data = action.payload || null;
      state.isopenIntegrationModal = true;
    },
    closeIntegrationModal: (state, action) => {
      state.isopenIntegrationModal = false;
    },
    openCaptchaModal: (state, action) => {
      state.data = action.payload || null;
      state.isopenCaptchaModel = true;
    },
    closeCaptchaModal: (state, action) => {
      state.isopenCaptchaModel = false;
    },
    openCategories: (state, action) => {
      state.data = action.payload || null;
      state.isopenCategories = true;
    },
    closeCategories: (state, action) => {
      state.isopenCategories = false;
    },
    openUpdateProfile: (state, action) => {
      state.data = action.payload || null;
      state.isopenUpdateProfile = true;
    },
    closeUpdateProflie: (state, action) => {
      state.isopenUpdateProfile = false;
    },
    openUpdatePreferences: (state, action) => {
      state.data = action.payload || null;
      state.isopenUpdatePreferences = true;
    },
    closeUpdatePreferences: (state, action) => {
      state.isopenUpdatePreferences = false;
    },
    openUpdateProfilePic: (state, action) => {
      state.data = action.payload || null;
      state.isopenUpdateProfilePic = true;
    },
    closeUpdateProfilePic: (state, action) => {
      state.isopenUpdateProfilePic = false;
    },
    openTribe: (state, action) => {
      state.data = action.payload || null;
      state.isopenTribe = true;
    },
    closeTribe: (state, action) => {
      state.isopenTribe = false;
    },
    openCreateTribe: (state, action) => {
      state.data = action.payload || null;
      state.isopenCreateTribe = true;
    },
    closeCreateTribe: (state, action) => {
      state.data = null;
      state.isopenCreateTribe = false;
    },
    openConfirmationModal: (state, action) => {
      state.data = action.payload || null;
      state.isopenConfirmationModal = true;
    },
    closeConfirmationModal: (state, action) => {
      state.data = null;
      state.isopenConfirmationModal = false;
    },
    openFilterModal: (state, action) => {
      state.isopenfilterModal = true;
    },
    closeFilterModal: (state, action) => {
      state.isopenfilterModal = false;
    },
    openUpdateSocialMediaModal: (state, action) => {
      state.isopenUpdateSocialMedia = true;
    },
    closeUpdateSocialMediaModal: (state, action) => {
      state.isopenUpdateSocialMedia = false;
    },
    openUpdateIntegrationModal: (state, action) => {
      state.isopenUpdateIntegrationModal = true;
    },
    closeUpdateIntegrationModal: (state, action) => {
      state.isopenUpdateIntegrationModal = false;
    },
    openReportUserModal: (state, action) => {
      state.reportUserDetails = action.payload || null;
      state.isopenReportUserModal = true;
    },
    closeReportUserModal: (state, action) => {
      state.reportUserDetails = null;
      state.isopenReportUserModal = false;
    },
    openSuccessfullCompletionModal: (state, action) => {
      state.isopenSuccessfullCompletionModal = true;
    },
    closeSuccessfullCompletionModal: (state, action) => {
      state.isopenSuccessfullCompletionModal = false;
    },
    openCreatePollModal: (state, action) => {
      state.pollData = action.payload;
      state.isOpenCreatePollModal = true;
    },
    closeCreatePollModal: (state, action) => {
      state.pollData = null;
      state.isOpenCreatePollModal = false;
    },
    openUpdatePhoneNumberModal: (state, action) => {
      state.data = action.payload;
      state.isopenUpdatePhoneNumberModal = true;
    },
    closeUpdatePhoneNumberModal: (state, action) => {
      state.isopenUpdatePhoneNumberModal = false;
    },
    openUpdatePhoneVerifyOtp: (state, action) => {
      state.data = action.payload;
      state.isopenUpdatePhoneVerifyOtp = true;
    },
    closeUpdatePhoneVerifyOtp: (state, action) => {
      state.isopenUpdatePhoneVerifyOtp = false;
    },
    openExitTribeConfirmationModal: (state, action) => {
      state.data = action.payload;
      state.isopenExitTribeConfirmation = true;
    },
    closeExitTribeConfirmationModal: (state, action) => {
      state.data = null;
      state.isopenExitTribeConfirmation = false;
    },
    openFeedbackModal: (state, action) => {
      state.isopenFeedbackModal = true;
    },
    closeFeedbackModal: (state, action) => {
      state.isopenFeedbackModal = false;
    },
    openSettingConfirmation: (state, action) => {
      state.isopenSettingConfirmationModal = true;
    },
    closeSettingConfirmation: (state, action) => {
      state.isopenSettingConfirmationModal = false;
    },
    openSkipTribeModal: (state, action) => {
      state.isopenSkipTribeModal = true;
    },
    closeSkipTribeModal: (state, action) => {
      state.isopenSkipTribeModal = false;
    },
    openPhoneBookModal: (state, action) => {
      state.isopenPhoneBookModal = true;
    },
    closePhoneBookModal: (state, action) => {
      state.isopenPhoneBookModal = false;
    },
    openMaximumTribesJoinedModal: (state, action) => {
      state.isopenMaximumTribesJoinedModal = true;
    },
    closeMaximumTribesJoinedModal: (state, action) => {
      state.isopenMaximumTribesJoinedModal = false;
    },
    openHideMyLocationFromUser: (state, action) => {
      state.hideLocationFrom = action.payload;
      state.isopenHideMyLocationFromUser = true;
    },
    closeHideMyLocationFromUser: (state, action) => {
      state.hideLocationFrom = null;
      state.isopenHideMyLocationFromUser = false;
    },
    openHideMyLocationFromAllUser: (state, action) => {
      state.hideLocationFrom = action.payload;
      state.isopenHideMyLocationFromAllUser = true;
    },
    closeHideMyLocationFromAllUser: (state, action) => {
      state.hideLocationFrom = null;
      state.isopenHideMyLocationFromAllUser = false;
    },
    openTribeExitedMaximumTimes: (state, action) => {
      state.isopenTribeExitedMaximumTimes = true;
    },
    closeTribeExitedMaximumTimes: (state, action) => {
      state.isopenTribeExitedMaximumTimes = false;
    },
    openToggleButtonModal: (state, action) => {
      state.isopenToggleButtonModal = true;
    },
    closeToggleButtonModal: (state, action) => {
      state.isopenToggleButtonModal = false;
    },
  },
});

export const {
  closeToggleButtonModal,
  openToggleButtonModal,
  openTribeExitedMaximumTimes,
  closeTribeExitedMaximumTimes,
  openHideMyLocationFromAllUser,
  closeHideMyLocationFromAllUser,
  openHideMyLocationFromUser,
  closeHideMyLocationFromUser,
  openMaximumTribesJoinedModal,
  closeMaximumTribesJoinedModal,
  openPhoneBookModal,
  closePhoneBookModal,
  openSkipTribeModal,
  closeSkipTribeModal,
  openSettingConfirmation,
  closeSettingConfirmation,
  closeSuccessfullCompletionModal,
  openSuccessfullCompletionModal,
  openReportUserModal,
  closeReportUserModal,
  openUpdateSocialMediaModal,
  closeUpdateSocialMediaModal,
  openUpdateIntegrationModal,
  closeUpdateIntegrationModal,
  openModal,
  closeModal,
  openLogin,
  closeLogin,
  openSignup,
  closeSignup,
  openSignupWithPhone,
  closeSignupWithPhone,
  openProfileDetails,
  closeProfileDetails,
  openUploadPic,
  closeUploadPic,
  openOtpModal,
  closeOtpModal,
  openPreferencesModal,
  closePreferencesModal,
  openSocialConnectionModal,
  closeSocialConnectionModal,
  closeIntegrationModal,
  openIntegrationModal,
  openCaptchaModal,
  closeCaptchaModal,
  closeCategories,
  openCategories,
  openUpdateProfile,
  closeUpdateProflie,
  openUpdatePreferences,
  closeUpdatePreferences,
  openUpdateProfilePic,
  closeUpdateProfilePic,
  openTribe,
  closeTribe,
  openCreateTribe,
  closeCreateTribe,
  openConfirmationModal,
  closeConfirmationModal,
  openFilterModal,
  closeFilterModal,
  openCreatePollModal,
  closeCreatePollModal,
  openUpdatePhoneNumberModal,
  closeUpdatePhoneNumberModal,
  openUpdatePhoneVerifyOtp,
  closeUpdatePhoneVerifyOtp,
  closeExitTribeConfirmationModal,
  openExitTribeConfirmationModal,
  openFeedbackModal,
  closeFeedbackModal,
} = modelSlice.actions;

export default modelSlice.reducer;
