import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { blockUser, getReportReasons, reportUser } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReportUserModal,
  openSuccessfullCompletionModal,
} from "../../redux/Reducers/modalReducer";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";

const Reportuser = (props) => {
  const dispatch = useDispatch();
  const { reportUserDetails } = useSelector((state) => state?.model);
  const [reportReasons, setReportReasons] = useState([]);
  const [selectedReasonIds, setSelectedReasonIds] = useState([]);

  const fetchReasons = async () => {
    try {
      const response = await getReportReasons();
      if (response.statusCode === 200) {
        setReportReasons(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReasons();
  }, []);

  const handleReasonSelection = (event) => {
    const reasonId = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedReasonIds([...selectedReasonIds, reasonId]);
    } else {
      setSelectedReasonIds(selectedReasonIds.filter((id) => id !== reasonId));
    }
    console.log(selectedReasonIds);
  };

  const handleBlockUser = async () => {
    try {
      let payload = { ...reportUserDetails, reasons: selectedReasonIds };
      let response = await blockUser(payload);
      if (response.statusCode === 200) {
        dispatch(closeReportUserModal());
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReportUser = async () => {
    try {
      let payload = { ...reportUserDetails, reasons: selectedReasonIds };
      let response = await reportUser(payload);
      if (response.statusCode === 200) {
        dispatch(closeReportUserModal());
        dispatch(openSuccessfullCompletionModal());
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal report_user_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <div className="reportsuccessfully_cntnt">
            <div className="reportsuccessfully_txt">
              <h2>
                Why do you want to <br></br>report this user?
              </h2>
            </div>
          </div>
          <div className="notifications_preferences text-left">
            <ul
              className="check_lis "
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              {reportReasons &&
                reportReasons.map((item) => (
                  <li key={item._id}>
                    <Form.Check
                      inline
                      label={item.reason}
                      name="reportReason"
                      type="checkbox"
                      id={`${item._id}`}
                      value={item._id}
                      checked={selectedReasonIds.includes(item._id)}
                      onChange={handleReasonSelection}
                    />
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="completeyour_profile_btn profile_photo_btn m-0">
            <Button variant="secondary" onClick={handleBlockUser}>
              BLOCK USER
            </Button>

            <Button
              className="btn_blue_large"
              variant="primary"
              onClick={handleReportUser}
            >
              REPORT USER
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Reportuser;
