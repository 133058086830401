import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import Succes from "../../assets/img/Succes.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeHideMyLocationFromUser, closeToggleButtonModal } from "../../redux/Reducers/modalReducer";
import { useNavigate } from "react-router-dom";
import { hideLocation, turnOnOffLocationSharingForAllTribeMembers } from "../../api/api";
import { toast } from "react-toastify";

const ToggleButtonModal = (props) => {
    const dispatch = useDispatch();
    const { hideLocationFrom } = useSelector((state) => state?.model);
    const handleYes = async () => {
        try {
            const dataToSend = {
                tribe_id: props?.tribe_id,
                is_location_sharing_enabled: true,
            };

            const result = await turnOnOffLocationSharingForAllTribeMembers(dataToSend);
            if (result.statusCode === 200) {
                props.setIsAllTribeMembersLocationEnabled(false);
                toast.success(result.message);
                dispatch(closeToggleButtonModal());
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleNo = () => {
        dispatch(closeToggleButtonModal());
    };

    return (
        <>
            <Modal
                {...props}
                className="sign_in_modal categories_modal fillter_categories_modal request_successfully_modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 pb-0">
                    <div className="reportsuccessfully_cntnt">
                        <div className="reportsuccessfully_icon">
                            {/* <img src={Succes} alt="Succes" /> */}
                        </div>
                        <div className="reportsuccessfully_txt">
                            <h2>Share your location to see other's locations on the map.</h2>
                            <p>
                                To view the map and see where others are, you need to enable location sharing.
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="completeyour_profile_btn profile_photo_btn m-0 d-flex">
                        <Button
                            variant="danger"
                            className="btn_blue_large"
                            onClick={handleYes}
                        >
                            Yes, share my location
                        </Button>
                        <Button
                            variant="primary"
                            className="btn_blue_large is_btn_active"
                            onClick={handleNo}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ToggleButtonModal;
