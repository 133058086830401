import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./NotFound";
import Layout from "../Component/Layout/Layout";
import Protected from "./Protected";
import IndexModal from "../modals/IndexModal";
import Payment from "../pages/Payment";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebaseSetup";
import { useSelector } from "react-redux";
import { addFirebaseToken } from "../api/api";
import Searchempty from "../pages/searchempty";
import PublicRoutes from "./PublicRoutes";
import FacebookLogin from "../pages/FacebookLogin";
import Festivals from "../pages/Festivals";
import Concerts from "../pages/Concerts";
// import IPInfo from "ip-info-react";

// Use lazy for component imports
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Event = lazy(() => import("../pages/Event"));
const Fillter = lazy(() => import("../Component/fillter/fillter"));
const Myevent = lazy(() => import("../pages/Myevent"));
const Requestsuccessfullysubmitted = lazy(() =>
  import(
    "../Component/requestsuccessfullysubmitted/requestsuccessfullysubmitted"
  )
);
const Profile = lazy(() => import("../pages/profile"));
const Yourfeedback = lazy(() => import("../modals/yourfeedback/yourfeedback"));
const Jointribe = lazy(() => import("../pages/Jointribe"));
const Createtribetwo = lazy(() =>
  import("../modals/createtribe/createtribetwo")
);
const Createtribethree = lazy(() =>
  import("../modals/createtribe/createtribethree")
);
const Tribewill = lazy(() => import("../Component/Tribewill/tribewill"));
const Settings = lazy(() => import("../pages/settings"));
const Suggestionsfeatures = lazy(() => import("../pages/suggestionsfeatures"));
const Termscondition = lazy(() => import("../pages/termscondition"));
const Firstmessage = lazy(() => import("../pages/firstmessage"));
const Reportuser = lazy(() => import("../modals/reportuser/reportuser"));
const UserProfile = lazy(() => import("../pages/UserProfile"));

const AppRoutes = () => {
  const { isUserLogedIn, user } = useSelector((state) => state.user);

  async function requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      localStorage.setItem("notificationDenied", 0);
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });

        console.log("Token generated : ", token);
        await addFirebaseToken({ _id: user._id, firebase_token: token });
      } catch (error) {
        console.log("Error in requesting permission", error);
      }
    }
    // else if (permission === "denied") {
    //   if (localStorage.getItem("notificationDenied") != 1) {
    //     localStorage.setItem("notificationDenied", 1);
    //     alert("You denied for the notification");
    //   }
    // }
  }

  useEffect(() => {
    if (isUserLogedIn && messaging !== null) {
      requestPermission();
    }
  }, [isUserLogedIn]);

  return (
    <>
      <IndexModal />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route element={<Protected />}>
            <Route path="Profile" element={<Profile />} />
            <Route path="Myevents" element={<Myevent />} />
            <Route exact path="myTribes" element={<Jointribe />} />
            <Route path="/user/profile/:user_id" element={<UserProfile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/suggestfeature" element={<Suggestionsfeatures />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/festivals" element={<Festivals />} />
          </Route>
          <Route exact path="/Reportuser" element={<Reportuser />} />
          <Route exact path="/Fillter" element={<Fillter />} />
          <Route exact path="/Tribewill" element={<Tribewill />} />
          <Route path="/firstmessage" element={<Firstmessage />} />
          <Route
            path="/Requestsuccessfullysubmitted"
            element={<Requestsuccessfullysubmitted />}
          />
          <Route path="/yourfeedback" element={<Yourfeedback />} />

          <Route path="*" element={<NotFound />} />
          <Route element={<PublicRoutes />}>
            <Route path="/concerts" element={<Concerts />} />
            <Route path="/event/:identifier" element={<Event />} />
            <Route path="/events" element={<Searchempty />} />
            <Route path="/termsconditions" element={<Termscondition />} />
            <Route path="/login" element={<FacebookLogin />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
