import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import VerificationImg from "../../assets/img/VerificationImg.jpg";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { addUserDetails } from "../../api/api";
import { closeCaptchaModal } from "../../redux/Reducers/modalReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUser, setUserLogin } from "../../redux/Reducers/userReducer";
import Backicon from "../../assets/img/Backicon.svg";

const Verification = (props) => {
  const dispatch = useDispatch();
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const handleSubmit = async () => {
    const response = await addUserDetails({ is_captacha_verified: true });
    if (response.statusCode === 200) {
      dispatch(setUser(response?.data));
      dispatch(setUserLogin(true));
      toast.success(response?.message);
      dispatch(closeCaptchaModal());
    } else {
      toast.error(response.error);
    }
  };
  const back = () => {
    dispatch(closeCaptchaModal());
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile"
        centered
      >
        <div className="flex_login_container">
          <div className="login_video">
            <div class="login_video_img">
              <img src={VerificationImg} alt="VerificationImg" />
            </div>
            {/* <video autoPlay loop muted className="w-full h-full object-cover">
              <source src={EventVideo} type="video/mp4" />
            </video> */}
          </div>
          <div className="welcome_logo">
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <div className="completeyour_profile">
                  <h2 onClick={back} className="back_arrow_signin">
                    <div className="back_profile_icon d-none">
                      <img
                        src={Backicon}
                        alt="logo-icon"
                        className="Backicon"
                      />
                    </div>
                    Complete your profile
                  </h2>
                </div>
              </Modal.Title>
            </Modal.Header>
            <div className="personal_info">
              <h2>
                <span>6</span> Verification{" "}
              </h2>
              <ProgressBar now={100} />;
            </div>
            <Form>
              <div className="cap_cha">
                <h3 className="profile_label">Confirm You're Not a Robot</h3>
                <div className="cap_cha_img">
                  <ReCAPTCHA
                    ref={null}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    size="normal"
                    onChange={handleCaptchaChange}
                  />
                </div>
              </div>
              <div className="completeyour_profile_btn">
                <Button
                  variant="secondary"
                  className={captchaToken && "is_btn_active"}
                  disabled={!captchaToken}
                  onClick={handleSubmit}
                >
                  Verify
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Verification;
