import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import modelReducer from "../Reducers/modalReducer";
import userReducer from "../Reducers/userReducer";
import queryReducer from "../Reducers/queryReducer";
import tribeReducer from "../Reducers/tribeReducer";
// Configuration for persisting the user reducer
const persistConfig = {
  key: 'root',
  storage,
};

// Root reducer combining persisted and non-persisted reducers
const rootReducer = combineReducers({
  user: persistReducer(persistConfig, userReducer), // persist only the user reducer
  model: modelReducer, // do not persist this reducer
  query:queryReducer,
  tribe: tribeReducer
});

const store = configureStore({
  reducer: rootReducer, // root reducer
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };

