import React from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import WelcomeLogo from "../../assets/img/WelcomeLogo.svg";
import { addUserDetails } from "../../api/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser, setUserLogin } from "../../redux/Reducers/userReducer";
import { closeProfileDetails } from "../../redux/Reducers/modalReducer";

// Validation schema
const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
});

const UserDetails = (props) => {
  const dispatch = useDispatch();
  const handleSubmit = async (values) => {
    try {
      const response = await addUserDetails({
        ...values,
        is_profile_completed: true,
      });
      if (response.statusCode === 200) {
        toast.success(response.message);
        dispatch(setUser(response?.data));
        dispatch(setUserLogin(true));
        // dispatch(openUploadPic());
        dispatch(closeProfileDetails());
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <Modal
      {...props}
      className="sign_in_modal welcome_modal completeyour_profile categories_modal fillter_categories_modal create_tribe_modal suggestion_mobile_sm newUserDetailsModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="pb-0">
        <Modal.Title className="text-center">
          <div className="welcome_logo p-1">
            <div className="welcome_logo_img mb-0">
              {/* <img src={WelcomeLogo} alt="WelcomeLogo" /> */}
            </div>
          </div>
          {/* <h2 className="pt-4 pb-0">Welcome to tribein!</h2> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="newModalBodyController">
        {/* <p>
          Please enter your first name. 
          We'll use this to verify your identity.
          Make sure it's something you'll remember and unique to you.
        </p> */}
        {/* <p>Enter your name.</p> */}
        <Formik
          initialValues={{ first_name: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit} // Pass handleSubmit directly
        >
          {({ isValid, touched, dirty }) => (
            <FormikForm className="user-details-form">
              <div className="name_your_tribe py-5 pb-0">
                <Form.Group
                  className="mb-3 newUsernameField"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <p className="mb-0">Enter your first name.</p> */}
                  <br />
                  <p className="text-center">Enter your name.</p>
                  <Field
                    name="first_name"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </div>

              <div className="welcome-action-btn py-4 mt-auto">
                <Button
                  type="submit"
                  className="w-100 btn-pad-second newButtonSpacing"
                  variant={isValid && dirty ? "big-submit" : "secondary"}
                  disabled={!isValid || !dirty}
                >
                  I AGREE
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UserDetails;
