import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import WelcomeLogo from "../../assets/img/WelcomeLogo.svg";
import Fb from "../../assets/img/Fb.svg";
import Apple from "../../assets/img/Apple.svg";
import Google from "../../assets/img/Google.svg";
import { useDispatch } from "react-redux";
import { closeSignup, openLogin } from "../../redux/Reducers/modalReducer";

const Login = (props) => {
  const dispatch = useDispatch();
  const handleSignUpWithPhone = () => {
    dispatch(closeSignup());
    dispatch(openLogin());
  };

  const handleGoogleSignup = () => {
    dispatch(closeSignup());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/google/signup`;
  };

  const handleAppleSignup = () => {
    dispatch(closeSignup());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/apple/signup`;
  };

  const handleFacebookSignup = () => {
    dispatch(closeSignup());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/facebook/signup`;
  };

  const handleSignup = () => {
    dispatch(closeSignup());
    dispatch(openLogin());
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal"
        centered
      >
        <Modal.Header closeButton className="p-0">
          <Modal.Title className="m-0"></Modal.Title>
        </Modal.Header>
        <div className="flex_login_container">
          <div className="login_video">
            <video
              autoPlay
              loop
              muted
              playsinline="true"
              className="w-full h-full object-cover"
            >
              <source src="https://tribe-in.com/video/Loginvideo.mp4" type="video/mp4" />
            </video>
            {/* <img src={LoginImg} alt="" /> */}
          </div>
          <div className="welcome_logo">
            <div className="welcome_logo_img">
              <img src={WelcomeLogo} alt="WelcomeLogo" />
            </div>
            <div className="welcome_logo_txt">
              <p>Connect. Engage. Experience Live Events Together.</p>
            </div>
            <div className="welcm_btn_list">
              <ul>
                
                <li>
                  <Button
                    variant="light"
                    className="white_btn text-uppercase"
                    onClick={handleAppleSignup}
                  >
                    <img src={Apple} alt="apple" />
                    Sign up WITH APPLE
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="white_btn text-uppercase"
                    onClick={handleGoogleSignup}
                  >
                    <img src={Google} alt="Google" />
                    Sign up WITH GOOGLE
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="white_btn facebook_btn text-uppercase"
                    onClick={handleFacebookSignup}
                  >
                    <img src={Fb} alt="Facebook" />
                    Sign up WITH FACEBOOK
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="sign_up_with text-uppercase"
                    onClick={handleSignUpWithPhone}
                  >
                    Sign up WITH PHONE NUMBER
                  </Button>
                </li>
              </ul>
            </div>
            <div className="already_have">
              <p>
                Already have an account?{" "}
                <Link onClick={handleSignup}>Sign In</Link>
              </p>
              <p className="signin_up">
                By signing up for TribeIn, you agree to our{" "}
                <Link
                  to="/termsconditions"
                  onClick={() => dispatch(closeSignup())}
                >
                  terms and conditions
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
